<template>
	<div class="wrapper pt-5">
		<div class="d-flex justify-content-between">
			<div style="width: 40px;"></div>
			<div class="d-flex justify-content-center" v-if="availableWidgetsDropdown.length > 1">
				<b-form-select :options="availableWidgetsDropdown" v-model="selectedWidget" />
				<button :disabled="selectedWidget == null" class="btn btn-success ml-5" @click="addWidget()">
					Add
				</button>
			</div>
			<div class="mr-10">
				<button :class="['btn', layoutNotSaved ? 'btn-success' : 'btn-secondary']" @click="saveLayout()" :disabled="!layoutNotSaved">
					Save Layout
					<span class="svg-icon svg-icon-sm svg-icon-warning" v-if="layoutNotSaved" v-b-tooltip="'Layout NOT Saved'">
						<inline-svg src="/media/svg/icons/Code/Warning-1-circle.svg" />
					</span>
					<b-spinner small v-if="layoutSaving" />
				</button>
			</div>
		</div>
		<GridLayout
			v-if="layout.length > 0"
			:layout.sync="layout"
			:col-num="4"
			:row-height="100"
			:is-draggable="true"
			:is-resizable="true"
			:vertical-compact="true"
			:use-css-transforms="true"
			class="vue-grid-layout mr-7"
		>
			<GridItem
				class="vue-grid-item"
				v-for="l in layout"
				v-bind:key="l.WidgetArrId"
				:static="false"
				:x="l.x"
				:y="l.y"
				:w="l.w"
				:h="l.h"
				:i="l.i"
				:maxH="1"
				@resized="gridChangeEvent"
				@moved="gridChangeEvent"
			>
				<div class="d-flex flex-column align-items-center">
					<div>
						{{ accountData.data.layout.widgets[l.i].Name }}
					</div>
					<div
						v-if="accountData.data.layout.widgets[l.i].PropertiesOption"
						:class="[
							'props ml-2 mt-2 btn btn-icon btn-sm',
							l.properties && Object.keys(l.properties).length != 0 ? 'btn-success' : 'btn-outline-dark',
						]"
						@click="dataToModal(l)"
						v-b-tooltip="'Edit Widget Properties'"
						style="cursor: pointer"
					>
						<!-- <inline-svg src="/media/svg/icons/Code/Settings4.svg" /> -->
						<i class="flaticon-interface-7" :class="{ 'text-white': l.properties && Object.keys(l.properties).length != 0 }"></i>
					</div>
				</div>
				<span id="removewidget" @click="removeWidget(l.i)" v-b-tooltip="'Remove widget from layout'">
					<!-- <inline-svg src="/media/svg/icons/Navigation/Close.svg" /> -->
					<i class="flaticon2-cross"></i>
				</span>
			</GridItem>
		</GridLayout>

		<b-modal size="lg" id="widgetModal-1008" @ok="ok()" ref="w1008" v-if="widModalData.properties && widModalData.WidgetId == 1008 && ipslalist">
			<template #modal-title>IP SLA Availability Widget Properties</template>
			<span class="bold">Show availability percentage for date range:</span><br />
			<b-form-radio-group button-variant="outline-primary" v-model="widModalData.properties.range" buttons :options="['Last Month', 'Last 30 days']">
			</b-form-radio-group
			><br /><br />
			<b-form-checkbox v-model="widModalData.properties.showaveragelatency">Show average latency</b-form-checkbox><br />
			<b-form-checkbox v-model="widModalData.properties.showaverageipsla">Show average jitter & packet loss</b-form-checkbox><br />

			<h4>Select IP SLA's to include in Availability List</h4>

			<div v-for="(i, sys) of sysnames" v-bind:key="sys">
				<span class="bold"
					>Router: <i>{{ sys }}</i></span
				>
				<b-table
					:fields="[
						{ key: 'show', label: 'Show' },
						{ key: 'ipsla', label: 'IP SLA' },
						{ key: 'alt', label: 'Alt. Label' },
					]"
					:items="ipslalist.filter(i => i.sysname == sys)"
				>
					<template #cell(show)="data"
						><b-form-checkbox :checked="checkIPSLAVal(data.item.id)" @change="checkIPSLA(data.item.id, $event)" />
					</template>
					<template #cell(ipsla)="data">{{ data.item.name }}</template>
					<template #cell(alt)="data"
						><b-form-input type="text" @change="labelIPSLA(data.item.id, $event)" :value="labelIPSLAVal(data.item.id)"
					/></template>
				</b-table>
			</div>
		</b-modal>

		<b-modal
			size="lg"
			id="widgetModal-1007"
			ref="w1007"
			@ok="ok()"
			v-if="widModalData.properties && widModalData.WidgetId == 1007 && ipslalistgrouped"
		>
			<template #modal-title>IP SLA Latency Widget Properties</template>
			<div>
				<div class="form-body">
					<div class="form-group">
						<span class="bold">Select graph layout type:</span><br /><br />
						<div class="row">
							<div class="col-md-6">
								<b-form-radio v-model="fdata.displaytype" name="displaytype" value="carousel">
									Carousel
								</b-form-radio>
							</div>
							<div class="col-md-6">
								<b-form-radio v-model="fdata.displaytype" name="displaytype" value="stack">
									Stack
								</b-form-radio>

								<b-form-checkbox :disabled="fdata.displaytype != 'stack'" v-model="fdata.displaytypenoscroll">
									Disregard Layout Vertical Size
									<small>Removes scroll bar to show all graphs, only works with full width widget</small>
								</b-form-checkbox>
							</div>
						</div>
					</div>

					<span class="bold">Choose IP SLA's to include from dropdown:</span><br /><br />
					<div v-if="ipslalistgrouped" :key="listrefresh">
						<div v-for="(n, i) of fdata.ipslaresults" v-bind:key="i">
							<div class="d-flex mb-4">
								<span style="width: 100px" class="mt-3">Group Label:</span>
								<b-form-input v-model="fdata.ipslaresults[i].label"></b-form-input>
								<span @click="removeGroup(i)" class="svg-icon svg-icon-sm svg-icon-dark" v-b-tooltip="'Remove IP SLA Group'">
									<inline-svg src="/media/svg/icons/Navigation/Close.svg" />
								</span>
							</div>
							<multiselect
								:id="'ms' + i"
								v-model="fdata.ipslaresults[i].ipslalistselected"
								:options="ipslalistgrouped"
								:multiple="true"
								:close-on-select="false"
								:clear-on-select="false"
								:preserve-search="true"
								placeholder="Pick IP SLA's"
								group-values="ipslalist"
								group-label="sysname"
								:group-select="true"
								@input="dispatchAction"
								label="name"
								track-by="name"
							></multiselect>
							<hr />
						</div>
						<button class="btn btn-sm btn-outline btn-success" @click="addGroup()">
							Add Group
						</button>
						<br />
					</div>
				</div>
			</div>
			<template slot="modal-ok">Save</template>
		</b-modal>
		<b-modal size="md" id="widget-modal-1009" ref="w1009" @ok="ok()" title="Select Video Stream" lazy>
			<div v-if="streamsLoaded">
				<b-form-group label="Select Stream">
					<b-form-select v-model="selectedStream" :options="streamOptions"> </b-form-select>
				</b-form-group>
			</div>
			<div v-else class="d-flex flex-column justify-content-center align-items-center">
				<b-spinner size="lg" class="mb-3" type="grow"></b-spinner>
				<h4>Loading Streams...</h4>
			</div>
		</b-modal>
	</div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

export default {
	name: 'DashboardLayout',
	props: ['data'],
	data() {
		return {
			accountData: {},
			layout: [],
			fdata: [],
			availableWidgets: [],
			layoutNotSaved: false,
			layoutSaving: false,
			selectedWidget: null,
			widModalData: {},
			ipslalist: null,
			ipslalistgrouped: null,
			listrefresh: 0,
			sysnames: {},
			oldprops: null,
			streamsLoaded: false,
			streamOptions: {},
			selectedStream: null,
		};
	},
	components: {
		GridLayout: VueGridLayout.GridLayout,
		GridItem: VueGridLayout.GridItem,
		Multiselect,
	},
	mounted() {
		this.loadData();
	},

	methods: {
		loadData() {
			this.accountData.data = JSON.parse(JSON.stringify(this.data));
			if (!this.accountData.data.layout.gridster && this.accountData.data.layout.widgets) {
				var rawlayout = [];
				for (var i = 0; i < this.accountData.data.layout.widgets.length; i++) {
					rawlayout.push({ col: 0, row: i, sizeX: 4, sizeY: 1, WidgetArrId: i });
				}
				this.layoutNotSaved = true;
				this.layout = this.layoutConverter(rawlayout);
			} else {
				this.layout = this.layoutConverter(this.accountData.data.layout.gridster);
			}

			this.accountData.data.layout.widgets.forEach((w, wi) => {
				var widgetInLayout = false;
				for (let g of this.layout) {
					if (g.WidgetArrId == wi) {
						widgetInLayout = true;
						break;
					}
				}
				if (!widgetInLayout) {
					this.availableWidgets.push(wi);
				}
			});
		},
		gridChangeEvent() {
			this.layoutNotSaved = true;
		},
		layoutConverter(gl) {
			var l = [];
			gl.forEach(g => {
				l.push({
					x: g.col,
					y: g.row,
					w: g.sizeX,
					h: 1,
					i: g.WidgetArrId,
					WidgetArrId: g.WidgetArrId,
					DeviceId: g.DeviceId,
					WidgetId: g.WidgetId,
					properties: g.properties || {},
				});
			});
			return l;
		},
		gridConvert() {
			var l = [];
			this.layout.forEach(g => {
				l.push({
					col: g.x,
					row: g.y,
					sizeX: g.w,
					sizeY: g.h,
					WidgetArrId: g.WidgetArrId,
					DeviceId: g.DeviceId,
					WidgetId: g.WidgetId,
					properties: g.properties,
				});
			});
			return l;
		},
		async saveLayout() {
			var gl = this.gridConvert();
			if (gl.length > 0) {
				this.layoutSaving = true;
				await this.$http.post('dashboardlayout', { username: this.accountData.data.username, layout: gl });
				this.layoutSaving = false;
				this.layoutNotSaved = false;
			}
		},
		removeWidget(i) {
			this.layout = this.layout.filter(l => {
				if (l.WidgetArrId === i) {
					this.availableWidgets.push(i);
					return false;
				}
				return true;
			});
			this.layoutNotSaved = true;
		},
		addWidget() {
			// get max row
			var mrow = 0;
			for (let l of this.layout) {
				if (l.y > mrow) {
					mrow = l.y;
				}
			}
			mrow++;
			this.layout.push({
				x: 0,
				y: mrow,
				w: 4,
				h: 1,
				i: this.selectedWidget,
				WidgetArrId: this.selectedWidget,
				DeviceId: this.accountData.data.layout.widgets[this.selectedWidget].DeviceId,
				WidgetId: this.accountData.data.layout.widgets[this.selectedWidget].WidgetId,
			});
			this.availableWidgets = this.availableWidgets.filter(f => f !== this.selectedWidget);
			this.layoutNotSaved = true;
		},
		async dataToModal(item) {
			this.widModalData = JSON.parse(JSON.stringify(item));
			this.fdata = {};
			if (!this.widModalData.properties) {
				this.widModalData.properties = {};
			} else {
				this.fdata = this.widModalData.properties;
			}

			if ([1007, 1008].includes(this.widModalData.WidgetId)) {
				this.ipslalist = null;
				this.sysnames = {};
				var res = await this.$http.get('swipsla/list?icmp=1');
				for (let n of res.data.data) {
					this.sysnames[n.sysname] = 1;
				}

				if (this.widModalData.WidgetId == 1007) {
					// populate dropdown options
					var iii = {};
					for (let n of res.data.data) {
						if (n.name != null && n.name != '') {
							if (!iii[n.sysname]) {
								iii[n.sysname] = {
									sysname: n.sysname,
									ipslalist: [
										{
											name: n.name,
											id: n.id,
										},
									],
								};
							} else {
								iii[n.sysname].ipslalist.push({ name: n.name, id: n.id });
							}
						}
					}
					this.ipslalistgrouped = [{ sysname: '', ipslalist: [] }];
					Object.keys(iii).forEach(n => {
						this.ipslalistgrouped.push(iii[n]);
					});

					if (!this.fdata.displaytype) {
						this.fdata.displaytype = 'carousel';
					}
					if (!this.fdata.ipslaresults) {
						this.fdata.ipslaresults = [{ label: null, ipslalistselected: [] }];
					}
				}
				if (this.widModalData.WidgetId == 1008) {
					this.ipslalist = res.data.data;
				}
				// v-if needs a sec or the modal is undefined
				setTimeout(() => {
					this.$refs['w' + this.widModalData.WidgetId].show();
					setTimeout(() => {}, 500);
				}, 100);
			} else {
				this.selectedStream = this.widModalData.properties?.stream;
				this.showVideoModal();
			}
		},
		// these functions make it so every ipsla doesn't need to be initialized
		checkIPSLAVal(id) {
			if (this.widModalData.properties.ipslaav?.[id]) {
				if (this.widModalData.properties.ipslaav[id].enabled) {
					return true;
				}
				return false;
			}
			return false;
		},
		checkIPSLA(id, value) {
			if (this.widModalData.properties.ipslaav?.[id]) {
				this.widModalData.properties.ipslaav[id].enabled = value;
			} else if (!this.widModalData.properties.ipslaav[id]) {
				this.widModalData.properties.ipslaav[id] = {
					enabled: true,
					name: this.ipslalist[id].name,
					label: null,
				};
			}
		},
		labelIPSLAVal(id) {
			if (this.widModalData.properties.ipslaav?.[id]) {
				return this.widModalData.properties.ipslaav[id].label;
			}
			return null;
		},
		labelIPSLA(id, value) {
			if (this.widModalData.properties.ipslaav?.[id]) {
				this.widModalData.properties.ipslaav[id].label = value;
			} else if (!this.widModalData.properties.ipslaav[id]) {
				this.widModalData.properties.ipslaav[id] = {
					enabled: false,
					name: this.ipslalist[id].name,
					label: value,
				};
			}
		},
		removeEntry() {},
		addGroup() {
			this.fdata.ipslaresults.push({ label: null, ipslalistselected: [] });
			this.listrefresh++;
		},
		removeGroup(i) {
			this.fdata.ipslaresults.splice(i, 1);
			this.listrefresh++;
		},
		dispatchAction(a) {
			this.listrefresh++;
		},
		showVideoModal() {
			this.streamsLoaded = false;
			this.$http.get(`streams/${this.currentUser.username}`).then(({ data }) => {
				this.streams = data;
				this.streamOptions = this.streams.map(stream => {
					return { value: stream.id, text: stream.stream_name };
				});
				this.streamsLoaded = true;
			});
			this.$bvModal.show(`widget-modal-${this.widModalData.WidgetId}`);
		},
		cancel() {
			this.widModalData = {};
		},
		async ok() {
			if (this.widModalData.WidgetId == 1007) {
				this.widModalData.properties = this.fdata;
			}
			if (this.widModalData.WidgetId == 1009) {
				this.widModalData.properties = { stream: this.selectedStream };
			}
			if (this.layoutNotSaved) {
				await this.saveLayout();
			}
			this.$http.post(`widgetproperties/${this.$route.params.id}/0/${this.widModalData.WidgetId}`, this.widModalData.properties).then(ret => {
				for (var i = 0; i < this.layout.length; i++) {
					if (this.layout[i].WidgetArrId == this.widModalData.WidgetArrId) {
						this.layout[i].properties = ret;
						this.listrefresh++;
						break;
					}
				}
				this.widModalData = {};
				this.$emit('save');
			});
		},
	},
	watch: {
		data: {
			handler: function(newData, oldData) {
				this.loadData();
			},
		},
	},
	computed: {
		...mapGetters(['currentUser']),
		availableWidgetsDropdown() {
			var dd = this.availableWidgets.map(i => {
				return { text: this.accountData.data.layout.widgets[i].Name, value: i };
			});
			dd.unshift({ text: 'Select Widget...', value: null });
			return dd;
		},
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.wrapper {
	background: #fff;
	margin-top: 1px;
	margin-bottom: 5px;
}
.vue-grid-item:not(.vue-grid-placeholder) {
	//background: linear-gradient(217deg, rgb(217, 220, 234), rgb(217, 219, 234));
	background: #ebecf5;
	border: 1px solid rgba(11, 65, 245, 0.2);
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 500;
}
.vue-grid-item .resizing {
	opacity: 0.9;
}
.vue-grid-item .static {
	background: #cce;
}
.vue-grid-item .text {
	font-size: 24px;
	text-align: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 100%;
	width: 100%;
}

.vue-grid-item .no-drag {
	height: 100%;
	width: 100%;
}
.vue-grid-item .minMax {
	font-size: 12px;
}
.vue-grid-item .add {
	cursor: pointer;
}
.vue-grid-item {
	#removewidget {
		position: absolute;
		top: 3px;
		right: 5px;
		cursor: pointer;
		transition: all 300ms ease-in-out;
		i {
			font-size: 14px;
			&:hover {
				color: $danger;
			}
		}
	}
	.props {
		i {
			color: black;
		}
	}
	::v-deep .vue-resizable-handle {
		background-size: 10px;
		bottom: 2px;
		right: 2px;
	}
}
.vue-draggable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 0;
	left: 0;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
		no-repeat;
	background-position: bottom right;
	padding: 0 8px 8px 0;
	background-repeat: no-repeat;
	background-origin: content-box;
	box-sizing: border-box;
	cursor: pointer;
}
.vue-resizable-handle {
	width: 40px;
}
</style>
